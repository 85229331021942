<template>
  <MemberSettingContainer>
    <!-- Loading -->
    <div v-if="isLoading" class="bc-my-3 bc-d-flex bc-justify-content-center">
      <b-spinner class="bc-text-primary" />
    </div>

    <!-- Settings form -->
    <GlobalSettings 
      @isLoading="(loading) => isLoading = loading" />

    <!-- Password form -->
    <b-card v-if="!isLoading && !currentCollective.hasSSO" class="bc-border bc-rounded">
      <validation-observer v-if="!send" #default="{ handleSubmit }" ref="refFormObserver">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <div class="bc-d-flex bc-flex-wrap bc-justify-content-between bc-my-2 bc-align-items-center">
            <h4 class="bc-text-secondary bc-mb-0">{{ $t('newPassword.title') }}</h4>
            <b-button 
              variant="primary" 
              type="submit"
              class="bc-btn bc-bg-primary bc-text-secondary"
            >
              {{ $t('form.actions.save') }}
            </b-button>
          </div>
          
          <b-row class="bc-mx-0">
            <b-col lg="6" class="bc-px-2">
              <!-- Current password -->
              <validation-provider #default="validationContext" name=" " rules="required">
                <b-form-group>
                  <label for="actualPassw" class="bc-fw-bold bc-text-secondary">
                    {{ $t('newPassword.form.password.label') }}*
                  </label>
                  <b-form-input
                    id="actualPassw"
                    v-model="form.password"
                    type="password"
                    :placeholder="$t('form.type-placeholder')"
                    :state="getValidationState(validationContext)"
                    class="bc-form-control"
                  />
                  <b-form-invalid-feedback class="bc-text-danger">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            
            <b-col lg="6" class="bc-px-2">
              <b-form>
                <label for="s" class="bc-fw-bold bc-text-secondary">
                  {{ $t('newPassword.knowledge') }}
                </label>
              </b-form>
              <b-button 
                :href="helpLocation" 
                target="_blank" 
                variant="outline-primary"
                class="bc-btn bc-border-primary bc-text-secondary"
              >
                {{ $t('backoffice.dashboard.help-center') }}
              </b-button>
            </b-col>
          </b-row>

          <!-- New password -->
          <b-row class="bc-mx-0">
            <b-col cols="6" class="bc-px-2">
              <validation-provider #default="validationContext" name=" " rules="required|max:255">
                <b-form-group>
                  <label for="newPassw" class="bc-fw-bold bc-text-secondary">
                    {{ $t('newPassword.form.password2.label') }}*
                  </label>
                  <b-form-input
                    id="newPassw"
                    v-model="form.newPassword"
                    type="password"
                    :placeholder="$t('form.type-placeholder')"
                    :state="getValidationState(validationContext)"
                    class="bc-form-control"
                  />
                  <b-form-invalid-feedback class="bc-text-danger">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6" class="bc-px-2">
              <!-- Repeat New password -->
              <validation-provider #default="validationContext" name=" " rules="required|max:255">
                <b-form-group>
                  <label for="newPassw2" class="bc-fw-bold bc-text-secondary">
                    {{ $t('newPassword.form.password3.label') }}*
                  </label>
                  <b-form-input
                    id="newPassw2"
                    v-model="form.newPassword2"
                    type="password"
                    :placeholder="$t('form.type-placeholder')"
                    :state="getValidationState(validationContext)"
                    class="bc-form-control"
                  />
                  <b-form-invalid-feedback class="bc-text-danger">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      
      <div v-else>
        <b-alert 
          variant="success" 
          show
          class="bc-alert bc-bg-primary bc-text-secondary bc-border-0"
        >
          {{ $t('newPassword.form.password-success') }}
        </b-alert>
      </div>
    </b-card>
    
    <!-- Danger Zone -->
    <DangerZone v-if="!isLoading" :userkey="loggedMember.key" />
  </MemberSettingContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required, max } from '@validations';
import MemberSettingContainer from '@/views/myself/components/MemberSettingContainer.vue';
// import GlobalSettings from '@/views/myself/components/GlobalSettings.vue';
// import DangerZone from '@/views/myself/components/DangerZone.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    MemberSettingContainer,
    GlobalSettings: () => import('@/views/myself/components/GlobalSettings.vue' /* webpackChunkName: "GlobalSettings" */),
    DangerZone: () => import('@/views/myself/components/DangerZone.vue' /* webpackChunkName: "DangerZone" */),
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      form: {
        password: '',
        newPassword: '',
        newPassword2: '',
      },
      required,
      max,
      isStaff: false,
      send: false,
      isLoading: false,
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    ...mapGetters(['loggedMember', 'currentCollective']),
    
    isSaveEnabled() {
      return (
        this.form.name
        && this.form.slug
        && (this.form.accessibilityAux || this.form.accessibility)
        && (this.form.visibilityAux || this.form.visibility)
      );
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isDisabled() {
      return !this.form.name || !this.form.slug || !this.form.visibility || !this.form.accessibility;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    helpLocation() {
      return process.env.VUE_APP_HELP_CENTER;
    },
  },
  async created() {
    this.isStaff = this.currentCollective.isStaff;
  },
  methods: {
    async onSubmit() {
      if (this.form.newPassword === this.form.newPassword2) {
        try {
          const response = await this.$store.dispatch('changePassword', {
            requestConfig: {
              oldPassword: this.form.password,
              password: this.form.newPassword,
              repeatPassword: this.form.newPassword2,
            },
          });
          if (response.data.ok) {
            this.notifySuccess(this.$t('backoffice.settings.messages.success'));
            this.send = true;
          }
          return response;
        } catch (error) {
          this.send = false;
          this.notifyError(this.$t('newPassword.form.same-old-password'));
        }
      } else {
        this.notifyError(this.$t('newPassword.form.not-same-password'));
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.settings-form {
  @include media-breakpoint-up(md) {
    width: 75%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
  .avatar {
    width: 80px !important;
    height: 80px !important;
  }
}

// Additional BDS-specific styles
.bc-form-control {
  &:focus {
    border-color: var(--primary);
    box-shadow: 0 0 0 0.2rem rgba(253, 218, 36, 0.25);
  }
}

.bc-btn {
  &.bc-bg-primary {
    &:hover, &:focus {
      background-color: darken(#FDDA24, 5%) !important;
    }
  }
}

// Override datepicker styles for Nexos theme
.field.has-value:not(.no-label) .field-input[data-v-5b500588] {
  color: var(--secondary);
}
</style>
